import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, map, withLatestFrom } from 'rxjs/operators';

import * as fromStore from '../store';

import { Observable, of } from 'rxjs';
import { isPlatformServer } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class InsightCodeGuard {
  constructor(
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return of(true);
    }

    return this.store.select(fromStore.getInsightCodeInitialized).pipe(
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(fromStore.InitInsightCode());
        }
      }),
      filter(loaded => loaded),
      map(loaded => loaded),
      take(1)
    );
  }
}
