import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, catchError, tap, filter, take, map } from 'rxjs/operators';
import { AuthState } from '../store';

import * as fromStore from '../store';
import * as fromRoot from '../../store';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard  {
  signedIn$: Observable<boolean> = of(false);
  signingIn$: Observable<boolean> = of(false);

  constructor(private store: Store<AuthState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkStore(route).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.store.select(fromStore.getAuthSignedIn),
      this.store.select(fromStore.getAuthChecked),
      this.store.select(fromStore.getAuthChecking),
      this.store.select(fromRoot.getRouterState),
    ]).pipe(
      tap(([signedIn, checked, checking, route]) => {
        if (!checked && !checking) {
          this.store.dispatch(fromStore.CheckAuth());
          return;
        }
        if (checked && signedIn) {
          this.store.dispatch(fromRoot.Go({ path: [route.state.queryParams['returnUrl'] || '/inzicht'] }));
        }
      }),
      map(([signedIn, checked, checking]) => !signedIn && checked && !checking),
      filter(loaded => loaded),
      take(1)
    );
  }
}
