<app-header [title]="'instellingen-pagina-titel' | fromDictionary" [imageUrl]="null"></app-header>

<section class="bg-space-gray-200 section-inset-y">
  <div class="container">
    <!--<h2 class="text-secondary mb-3">{{ 'instellingen-pagina-titel' | fromDictionary }}</h2>-->

    <div class="row">
      <div class="col-md-6">
        <div class="card mb-4">
          <app-notification-settings-form></app-notification-settings-form>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <h2 class="fw-bold text-primary fs-body-md font-secondary">{{ 'huidig-adres-hulptekst' | fromDictionary }}</h2>
          <p class="lh-base">
            <ng-container *ngFor="let line of formattedAddressLines()">
              {{ line }}
              <br />
            </ng-container>
          </p>

          <div class="text-center">
            <button class="button button--secondary" *ngIf="address$()" (click)="signOut()">
              {{ 'app-uitloggen-knoptekst' | fromDictionary }}
            </button>
          </div>
        </div>
        <div class="card mt-3" *ngIf="address$()?.hasWasteCard">
          <app-waste-card-number-form></app-waste-card-number-form>
        </div>
        @if (hasInsights$()) {
          <div class="card mt-3">
            <app-insight-code-form></app-insight-code-form>
          </div>
        }
        <div class="card mt-3">
          <h2 class="fw-bold text-primary fs-body-md font-secondary">{{ 'app-cookie-settings-titel' | fromDictionary }}</h2>
          <p class="lh-base" *ngIf="'app-cookie-settings-tekst' | fromDictionary: false">
            {{ 'app-cookie-settings-tekst' | fromDictionary }}
          </p>
          @if (trackingStatus$() !== 'authorized') {
            <p class="alert alert--warning">
              {{ 'app-ios-tracking-framework-waarschuwing-tekst' | fromDictionary }}
            </p>
          }
          <div class="text-center">
            <button (click)="openCookie()" [disabled]="trackingStatus$() !== 'authorized'" class="button button--secondary">
              {{ 'cookie-settings' | fromDictionary }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
