import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserSettingsComponent } from './containers/user-settings/user-settings.component';
import { GripLocationsGuard } from '../notifications/guards';
import { WasteCalendarGuard } from '../waste-calendar/guards';
import { InsightCodeGuard } from '../auth/guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: UserSettingsComponent,
    canActivate: [GripLocationsGuard, WasteCalendarGuard(5), InsightCodeGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
