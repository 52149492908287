import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromStore from './store';

import { SettingsRoutingModule } from './settings-routing.module';
import { UserSettingsComponent } from './containers/user-settings/user-settings.component';
import { NotificationsModule } from '../notifications/notifications.module';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { WasteCalendarModule } from '../waste-calendar/waste-calendar.module';
import { AddressesModule } from '../addresses';
import { CoreModule } from '../core/core.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthModule } from '../auth/auth.module';
import { InsightModule } from '../insight/insight.module';

@NgModule({
  declarations: [UserSettingsComponent],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    AddressesModule,
    NotificationsModule,
    WasteCalendarModule,
    DictionaryNgrxModule,
    InsightModule,
    AuthModule,
    CoreModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.appEffects),
  ],
})
export class SettingsModule {}
