import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { ClearAddress, ClearWasteCardNumber, getAddress } from 'src/app/addresses/store';
import { SignOut, getAuthSignedIn } from 'src/app/auth/store';
import { DeleteGripSubscriptions, DeleteSubscriptions, ResetGripLocations, getNotificationToken } from 'src/app/notifications/store';
import { DeleteManualSubscriptions } from 'src/app/notifications/store/actions/manual-subscriptions.action';
import { Capacitor } from '@capacitor/core';
import { AppTrackingService } from '../../../core/services';
import { ClearCompletedProfile } from '../../store';
import { getHasDepositsMunicipalities } from 'src/app/insight/store';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsComponent {
  private store = inject(Store);
  private appTracking = inject(AppTrackingService);

  FCMToken$ = this.store.selectSignal(getNotificationToken);
  address$ = this.store.selectSignal(getAddress);
  signedIn$ = this.store.selectSignal(getAuthSignedIn);
  hasInsights$ = this.store.selectSignal(getHasDepositsMunicipalities);

  trackingStatus$ = this.appTracking.status$;

  formattedAddressLines() {
    if (!this.address$()) {
      return;
    }

    const { street, city, houseNumber, addition, postalCodeFull } = this.address$()!;
    return [`${street} ${houseNumber} ${addition || ''}`, `${[postalCodeFull, city].join(' ')}`];
  }

  async signOut() {
    const { value } = await Dialog.confirm({
      title: 'Weet u het zeker?',
      message: 'Weet u zeker dat u wilt uitloggen? Uw notificatie-instellingen worden ook verwijderd.',
      okButtonTitle: 'OK',
      cancelButtonTitle: 'Annuleren',
    });
    if (!value) {
      return;
    }

    if (this.signedIn$()) {
      this.store.dispatch(SignOut({}));
    } else {
      this.store.dispatch(ClearAddress({ redirect: true }));
      this.store.dispatch(ClearWasteCardNumber());
    }
    this.store.dispatch(ClearCompletedProfile());
    this.store.dispatch(DeleteSubscriptions({ token: this.FCMToken$() }));
    this.store.dispatch(DeleteGripSubscriptions({ token: this.FCMToken$() }));
    this.store.dispatch(DeleteManualSubscriptions({ token: this.FCMToken$() }));
    this.store.dispatch(ResetGripLocations());
  }
  async openCookie() {
    if (Capacitor.getPlatform() === 'ios') {
      var response = await this.appTracking.getStatus();
    }
    this.store.dispatch(ShowCookieSettings());
  }
}
